import React, { useState, useEffect } from "react";
import gql from 'graphql-tag';
import "../styles/components/password-toogle.scss";
import icon from "../img/button-pass.svg"
import { useMutation } from '@apollo/react-hooks';

const PasswordToogle = props => {
    const [hidden, setHidden] = useState(true);
    const [password, setPassword] = useState(props.password);
    const organizationID = props.organizationID;
    const userID = props.userID;

    const RESET_QUICK_PASS = gql`
    mutation {
        updateUserQuickpass(organizationId: "${organizationID}", userId: "${userID}") {
            id
            organization {
                id
                name
            }
            user {
                id
                name
            }
            password
        }
    }
  `;

    const [resetQuickPass, { data: newPassword }] = useMutation(RESET_QUICK_PASS);

    const toggleShow = () => {
        setHidden(!hidden);
    };

    useEffect(() => {

        if (newPassword !== undefined) {
            setPassword(newPassword.updateUserQuickpass.password);
        }

    }, [newPassword]);

    return (
        <div className="password">
            <form action="">
                <label htmlFor="pass">Access Code:</label>
                <input
                    name="pass"
                    type={hidden ? "password" : "text"}
                    value={password}
                    readOnly
                />
            </form>
            <button onClick={toggleShow}>
                <img
                    src={process.env.PUBLIC_URL + icon}
                    alt="button-pass"
                />
            </button>

            <button onClick={resetQuickPass} className="reset-btn">
                Reset Code
            </button>
        </div>
    );
};

export default PasswordToogle;
