import React from 'react';
import './styles/App.scss';
import Container from "./components/container";

function App() {
  return (
    <div className="container-fluid">
      <Container></Container>
    </div>
  );
}

export default App;
