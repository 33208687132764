import React, { useState } from "react";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import "../styles/components/experience-details.scss";

//graphic comps
import Graphic from "./graphics";
import List from "./list";
import PercentageBars from "./percentage-bars";
import CircularProgress from "./circular-progress";
import Loader from "./loader";
import ErrorScreen from './error-screen';
import Breadcrumb from './breadcrumb';

const ExperienceDetails = (props) => {

    const [experience] = useState(props.location.state.selectedExperience);
    const [from] = useState(props.location.state.from); 

    const users = gql`
        query {
            getUsersFromModule(moduleId: "${experience.id}") {
                id
                name
                email
                quickpasses {
                    password
                }
                experiences {
                    id
                    score
                    completionDate
                    module {
                        id
                        name
                    }
                    sessions{
                        id
                        created
                        time
                    }      
                    sceneData {
                        continuity
                      	continuityUntilCompletion
                        completionDate
                        score
                        scene {
                            id
                            name
                            maxScore
                            sceneData {
                                time
                                score
                                continuity
                                continuityUntilCompletion
                                completionDate
                            }
                        }
                        evaluationAnswers{
                            session{
                                id
                                created
                            }
                            question{
                                question
                                options
                            }
                            answer
                            result
                            group
                        }
                        additions{
                            json
                            type
                        }
                    }
                }
            }
        }  
    `;

    const scenes = gql`
    query {
        getScenesFromModule(moduleId: "${experience.id}")
            { 
                id 
                name 
            }
        }
    `;

    const { loading: loadingUsers, error: errorUsers, data: dataUsers } = useQuery(users);
    const { loading: loadingScenes, error: errorScenes, data: dataScenes } = useQuery(scenes);

    if (loadingUsers) return <Loader />;
    if (loadingScenes) return <Loader />;

    if (errorUsers) return <ErrorScreen />;
    if (errorScenes) return <ErrorScreen />;

    const getActivity = (users) => {
        let wip = 0;
        let newUser = 0;
        let completed = 0
        let sessions = users.map(exp => {
            let ses
            exp.experiences.map(item => {
                if (item.module.id === experience.id) {
                    ses = item;
                }
                return (item)
            })
            return ses
        });

        sessions.map(ses => {
            if (ses.sessions.length === 0) {
                newUser++;
            }
            if (ses.score === 0 && ses.sessions.length > 0) {
                wip++;
            }
            if (ses.score > 0) {
                completed++;
            }
            return (ses)
        })

        let labels = ["In Progress", "Completed", "New"];
        let data = [wip, completed, newUser];

        return (
            <Graphic
                title="Activity"
                date=''
                type="pie"
                unique_id="pie-graph"
                labels={labels}
                data={data}
                backgroundColor={[
                    "#8eaae7",
                    "#4a487d",
                    "#4e72bf"
                ]}
                legend={true}
                footnote={'Total Amount of Users: ' + users.length + ' Users'}
            />
        )
    };

    const getLabels = () => {
        let labels = [];
        dataScenes.getScenesFromModule.forEach(lbl => {
            labels.push(lbl.name);
        });
        return labels
    }

    const getVisitsPerScene = (users) => {
        let scenes = users.map(exp => {
            let ses
            exp.experiences.map(item => {
                if (item.module.id === experience.id) {
                    ses = item;
                }
                return (item)
            })
            return ses
        });

        let values = [];

        scenes[0].sceneData.map(ses => {
            let total = ses.scene.sceneData.reduce((sum, item) => {
                return sum = sum + item.continuity;
            }, 0)

            values.push(total);
            return (ses)
        })

        return (
            <Graphic
                title="Visits per Scene"
                date=""
                labels={getLabels()}
                data={values}
                backgroundColor={"#cf6b6f"}
                type="bar"
                unique_id="bar-graph-experiences-1"
            />
        )
    }

    const getAverageDuration = (users) => {
        let data = [0, 0, 0, 0];
        users.map(user => {
            let time = 0;
            user.experiences[0].sessions.map(ses => {
                time += ses.time
                return (ses)
            })
            if (time > 0 && time <= 240) {
                data[0]++;
            }
            if (time > 240 && time <= 420) {
                data[1]++;
            }
            if (time > 420 && time <= 600) {
                data[2]++;
            }
            if (time > 600) {
                data[3]++;
            }
            return (user)
        })
        return (
            <Graphic
                title="Average duration:"
                date=""
                labels={["1s - 4min", "4min - 7min", "7min - 10min", "10min - ++"]}
                data={data}
                backgroundColor={"#2465a5"}
                type="bar"
                unique_id="bar-graph-experiences-2"
                footnote="Average time of completion of the experience by amount of users"
            />
        )
    }

    const getTotalTimeSpent = (users) => {
        let scenes = users.map(exp => {
            let ses
            exp.experiences.map(item => {
                if (item.module.id === experience.id) {
                    ses = item;
                }
                return (item)
            })
            return ses
        });

        let values = [];
        let totalTime = 0;

        scenes[0].sceneData.map(ses => {
            let total = ses.scene.sceneData.reduce((sum, item) => {
                return sum = sum + item.time;
            }, 0)
            totalTime += total;
            values.push((total / 60 / 60).toFixed(1));
            return (ses)
        })

        return (
            <Graphic
                title="Total Time Spent"
                date=""
                labels={getLabels()}
                data={values}
                backgroundColor={["#d36a70", "#38bfae", "#663082", "#2b85a8", "#4e72bf"]}
                type="doughnut"
                unique_id="pie-graph-experiences-1"
                legend={true}
                footnote={'Time shown in hours, Total Time Spent on Experiences: ' + (totalTime / 60 / 60).toFixed(1) + ' Hours'}
            />
        )
    }

    const getCompletedScenes = (users) => {
        let labels = getLabels()
        let colors = ["#4e72bf", "#d36a70", "#38bfae", "#663082", "#2b85a8"];
        let data = labels.map((scene, index) => {
            let totalUsers = 0
            users.map(user => {
                user.experiences[0].sceneData.map(scn => {
                    if (scn.scene.name === scene && scn.completionDate != null) {
                        totalUsers++;
                    }
                    return (scn)
                })
                return (user)
            }
            )
            return ({ label: scene, degree: (totalUsers * 180) / users.length, totalUsers: totalUsers, color: colors[index] })
        })

        return (
            <div className="circular-progress-cont">
                <div className="header-container">
                    Completed Scenes
                </div>

                <div className="row justify-content-center">
                    {data.map((scene, i) => {
                        return (
                            <div className="col-4" key={i}>
                                <CircularProgress
                                    degrees={scene.degree}
                                    users={scene.totalUsers}
                                    color={scene.color}
                                />
                            </div>
                        )
                    })}
                </div>
                <div className="labels">
                    {getLabels().map((label, i) => {
                        return (
                            <div className="lbl-cont" key={i}>
                                <div className="color" style={{ backgroundColor: colors[i] }}></div>
                                <div className="label">
                                    {label}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    const getMetricsByPercentage = (users) => {
        let data = []
        let totalUsers = users.length;
        let fisrtTry = 0;
        let returnedOncefinished = 0
        let revisitedScenes = 0
        let topScores = 0


        users.map(user => {
            let experience = user.experiences[0]

            let userRevisit = experience.sceneData.filter(sData => {
                return sData.completionDate != null && sData.continuity > sData.continuityUntilCompletion;
            })
            let sessions = experience.sessions.filter(ses => {
                return experience.completionDate != null && new Date(experience.completionDate) < new Date(ses.created);
            })

            let sessionsAfterCompletion = experience.sessions.filter(ses => {
                return experience.completionDate != null && new Date(experience.completionDate) > new Date(ses.created)
            })

            if (sessionsAfterCompletion.length > 0) {
                fisrtTry++
            }
            if (sessions.length > 0) {
                returnedOncefinished++
            }
            if (userRevisit.length > 0) {
                revisitedScenes++;
            }
            if (experience.score >= 90) {
                topScores++;
            }
            return (user)
        })
        data.push({ title: 'Finished the experience in the first try', percentage: Math.round((fisrtTry * 100) / totalUsers) })
        data.push({ title: 'Returned to the experience once finished', percentage: Math.round((returnedOncefinished * 100) / totalUsers) })
        data.push({ title: 'Revisited completed scenes', percentage: Math.round((revisitedScenes * 100) / totalUsers) })
        data.push({ title: 'Getting scores between 100 and 90 ', percentage: Math.round((topScores * 100) / totalUsers) })
        return (
            <PercentageBars
                title="Users Metrics by Percentages"
                type="multiple"
                data={data}
            />
        )
    }

    const getAverageScore = (users) => {
        let scenes = users.map(exp => {
            let ses
            exp.experiences.map(item => {
                if (item.module.id === experience.id) {
                    ses = item;
                }
                return (item)
            })
            return ses
        });

        let scores = scenes.filter(ses => {
            return ses.score > 0
        })
        let average = 0;
        scores.forEach(score => {
            average += score.score
        })

        average = Math.round(average / scores.length);


        return (
            <PercentageBars
                title="Average Score"
                percentage={average}
            />
        )
    }
    const getCompletionStatus = users => {
        return (
            <Graphic
                title="Simulation: Identifying Physical Behaviors"
                date=""
                labels={['Did not completed', 'Completed at second try', 'Completed at first try']}
                data={[10, 4, 5]}
                backgroundColor={["#f175b7", "#6061ef", "#b373f0"]}
                type="doughnut"
                unique_id="pie-graph-experiences-2"
                legend={true}
                footnote={'Time shown in hours, Total Time Spent on this scene: ' + 0 + ' Hours'}
            />
        )
    }

    const getAverageAnserVerbalDeescalation = users => {
        let allGoodAnswers = 0;
        let failedOneToThree = 0;
        let failedMoreThanFour = 0
        let usersFiltered = users.filter(user => {
            let sceneData = user.experiences[0].sceneData[3];
            return sceneData ? (sceneData.score !== 0) : (false)
        })

        usersFiltered.forEach(user => {
            let data = [];
            let incorrect = 0

            let questions = user.experiences[0].sceneData[2].evaluationAnswers
            let labels = questions.map(q => {
                return q.question.question
            })
            let filteredLabels = labels.filter((item, i) => {
                return labels.indexOf(item) === i
            })

            filteredLabels.forEach(name => {
                let group = questions.filter(item => {
                    return item.question.question === name
                })
                let recentDate = new Date(Math.max.apply(null, group.map(function (e) {
                    return new Date(e.session.created);
                })));
                let recentQuestions = questions.filter(item => {
                    return new Date(item.session.created).toString() === recentDate.toString() && item.question.question === name;
                })
                data.push(recentQuestions[0]);
            })

            data.forEach(q => {
                if (q.result === "Incorrect") {
                    incorrect++;
                }
            })
            if (incorrect === 0) {
                allGoodAnswers++
            }
            if (incorrect >= 1 && incorrect <= 3) {
                failedOneToThree++
            }
            if (incorrect >= 4) {
                failedMoreThanFour++
            }
        })



        return (
            <Graphic
                title="Simulation: Verbal De-Escalation"
                date=""
                labels={["Answered all good", "Failed one to three", "Failed four or more"]}
                data={[Math.round((allGoodAnswers * 100) / usersFiltered.length), Math.round((failedOneToThree * 100) / usersFiltered.length), Math.round((failedMoreThanFour * 100) / usersFiltered.length)]}
                backgroundColor={["#913d6c", "#3023ae", "#53a0fd"]}
                type="bar"
                unique_id="bar-graph-experiences-3"
                footnote="Data shown in percetages"
            />
        )
    }

    const getAverageScoreAvodingStrikes = users => {

        let scores = users.filter(user => {
            let sceneData = user.experiences[0].sceneData[3];
            return sceneData ? (sceneData.score !== 0) : (false)
        })

        let percentages = scores.map(user => {
            let per = user.experiences[0].sceneData[3];
            return (per.score * 100) / per.scene.maxScore
        })

        let totalScores = 0
        percentages.forEach(score => {
            totalScores += score;
        })
        return (
            <PercentageBars
                title="Simulation: Avoiding Strike"
                percentage={Math.round(totalScores / percentages.length)}
            />
        )
    }

    return (

        <div className="experiences-details">

            <Breadcrumb from={[...from?(from):(""), { path: "/experience-details", name: experience.name, state: { selectedExperience: experience } }]}></Breadcrumb>
            <div className="row">
                <div className="col-6">
                    <List
                        from={[...from?(from):(""), { path: "/experience-details", name: experience.name, state: { selectedExperience: experience } }]}
                        title={"Users with Access"}
                        isUserList={true}
                        users={dataUsers.getUsersFromModule}
                    />
                </div>

                <div className="col-6">
                    {getActivity(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-6">
                    {getVisitsPerScene(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-6">
                    {getAverageDuration(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-6">
                    {getTotalTimeSpent(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-6">
                    {getCompletedScenes(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-12">
                    {getMetricsByPercentage(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-12">
                    {getAverageScore(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-6">
                    {getCompletionStatus(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-6">
                    {getAverageAnserVerbalDeescalation(dataUsers.getUsersFromModule)}
                </div>

                <div className="col-12">
                    {getAverageScoreAvodingStrikes(dataUsers.getUsersFromModule)}
                </div>
            </div>
        </div>
    );
}

export default ExperienceDetails;