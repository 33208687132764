import React, { Component } from "react";
import Searchbar from "./searchbar";
import { Link } from "react-router-dom";

import "../styles/components/list.scss";
// import userLogo from "../img/menu-icons/user.svg"

export default class List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showUser: false,
            searchText: "",
            from: this.props.from,
            filtered: this.props.isUserList
                ? this.props.users
                : this.props.experiences
        };
        this.searchTextChangedHandler = this.searchTextChangedHandler.bind(this);
    }

    userClicked = (item) => {

        this.setState({
            showUser: true,
            item: item
        });
    };

    searchTextChangedHandler = event => {
        let currentList = [];
        let newList = [];

        if (event.target.value !== "") {
            currentList = this.props.isUserList
                ? this.props.users
                : this.props.experiences;
            newList = currentList.filter(item => {
                const temp = item.name.toLowerCase();
                const filter = event.target.value.toLowerCase();
                return temp.includes(filter);
            });
        } else {
            newList = this.props.isUserList
                ? this.props.users
                : this.props.experiences;
        }

        this.setState({
            searchText: event.target.value,
            filtered: newList
        });
    };

    lastSession = data => {
        var mostRecentDate = new Date(Math.max.apply(null, data.experiences.map(item => {
            if (item.sessions.length === 0) {
                return 'Invalid Date'
            }
            var sessionDates = item.sessions[item.sessions.length - 1].created;
            return new Date(sessionDates);
        })));
        if (mostRecentDate.toString() === 'Invalid Date') {
            return ('No Experiences Started')
        }
        var mostRecentExperience = data.experiences.filter(item => {
            if (item.sessions.length === 0) {
                return 'Invalid Date'
            }
            var date = new Date(item.sessions[item.sessions.length - 1].created);
            return date.getTime() === mostRecentDate.getTime();
        })[0];
        return (mostRecentExperience.module.name);
    };

    lastSessionDate = data => {
        //lastSession.sessions ? (new Date(lastSession.sessions[0].created).toDateString())
        // var mostRecentDate = new Date(Math.max.apply(null, data.experiences.map(item => {
        //     if (item.sessions.length === 0) {
        //         return 'Invalid Date'
        //     }
        //     var sessionDates = item.sessions[item.sessions.length - 1].created;
        //     return new Date(sessionDates);
        // })));
        // if (mostRecentDate.toString() === 'Invalid Date') {
        //     return ('')
        // }
        // var weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        // var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        // return (weekday[mostRecentDate.getDay()] + " " + mostRecentDate.getDay() + ", " + months[mostRecentDate.getMonth()] + " " + mostRecentDate.getHours() + ":" + mostRecentDate.getMinutes());
        let mostRecentDate = new Date(Math.max.apply(null, data.experiences.map(item => {
            let sessionDates = item.sessions[item.sessions.length - 1].created;
            return new Date(sessionDates);
        })));
        if (mostRecentDate.toString() === "Invalid Date") {
            return ('No Experiences Started')
        }
        let mostRecentExperience = data.experiences.filter(item => {
            let date = new Date(item.sessions[item.sessions.length - 1].created);
            return date.getTime() === mostRecentDate.getTime();
        })[0];
        return (new Date(mostRecentExperience.sessions[0].created).toDateString());
    };

    render() {
        return (
            <div className="list-container">
                <div className="header-container">
                    <div className="title">{this.props.title} </div>
                    <Searchbar
                        text={this.state.searchText}
                        onTextChanged={this.searchTextChangedHandler}
                    />
                </div>

                <div className="list-content">
                    {this.props.isUserList
                        ? this.state.filtered.map((item, i) => {
                            return (
                                <Link key={i} to={{ pathname: '/user-details', state: { selectedUser: item, from: [{ path: "/general-analytics", name: "General Analytics"}] } }}>
                                    <div
                                        key={i}
                                        className="user"
                                        onClick={e => this.userClicked(item)}
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/dummy-info/user-1.jpg"}
                                            alt="user-avatar"
                                        />
                                        <div className="name-container">
                                            <div className="name">{item.name}</div>
                                            <div className="last">Last: {this.lastSession(item)}</div>
                                        </div>
                                        <div className="date">{this.lastSessionDate(item)}</div>
                                    </div>
                                </Link>
                            );
                        })
                        : this.state.filtered.map((item, i) => {
                            return (
                                <Link key={i} to={{ pathname: '/experience-details', state: { selectedExperience: item, from: [{ path: "/general-analytics", name: "General Analytics" }] } }}>
                                    <div key={i} className="experience">
                                        <img
                                            src={process.env.PUBLIC_URL + "/dummy-info/experience-2.jpg"}
                                            alt="experience-avatar"
                                        />
                                        <div className="name">{item.name}</div>
                                    </div>
                                </Link>
                            );
                        })}
                </div>
            </div>
        );
    }
}
