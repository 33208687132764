import React from "react";
import "../styles/components/circular-progress.scss";

const CircularProgress = (props) => {
    let rotate = "rotate("+props.degrees+"deg)"
    return(
        <div className="circle-wrap">
            <div className="circle">
                
                <div className="mask full" style={{transform: rotate}}>
                    <div className="fill" style={{backgroundColor: props.color, transform: rotate}}></div>
                </div>
            
                <div className="mask half">
                    <div className="fill" style={{backgroundColor: props.color, transform: rotate}}></div>
                </div>
                
                <div className="inside-circle">
                    <span>{props.users}</span>
                    {props.noText?(''):('users')}
                </div>
                
            </div>
        </div>
    )
}

export default CircularProgress;