import React from "react"
import "../styles/components/error-screen.scss"

const ErrorScreen = (organizationID) => {
    console.log(organizationID.organizationID)
    return(
       <div className="error-message row">
           <div className="col-5 offset-1">
               <div className="title">
                    Ooops! That page doesn’t exist!
               </div>
               <div className="text">
                    Sorry for this inconvenience. It looks like you’re trying to access a page that either has been deleted or never even existed.
               </div>
           </div>
           <div className="col-5">
                <img
                    src={process.env.PUBLIC_URL + "/error-illustration.png"}
                    alt="user-avatar"
                />
           </div>
       </div>
    )
}

export default ErrorScreen;