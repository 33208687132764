import React, { useState, useEffect } from "react";
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import "../styles/components/login.scss";
import Loader from "./loader";

const LogIn = ({ setIsLoggedin, setUserType, setOrganizationID }) => {
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [failedLogIn, setFailedLogIn] = useState(false);
    const [loading, setLoading] = useState(true);

    const LOG_IN_QUERY = gql`
    mutation {
        loginUser(email: "${user}", password: "${pass}") {
          user {
            id
            name
            email
            role
            organization {
                id
                name
            }
          }
          token
        }
    }
  `;

    const IS_LOGGED_IN = gql`
        query {
            currentUser {
            id
            organization {
                id
                name
            }
            name
            email
            password
            role
            experiences {
                id
            }
            }
        }
    `;

    const { data: dataUserCheck } = useQuery(IS_LOGGED_IN);

    if (dataUserCheck) {
        setIsLoggedin(true)
        setOrganizationID(dataUserCheck.currentUser.organization[0].id)
        setUserType(dataUserCheck.currentUser.role)
    }

    const [getLogged, { data: dataUser }] = useMutation(LOG_IN_QUERY, {
        onCompleted() {
            setFailedLogIn(false)
        },
        onError() {
            setFailedLogIn(true)
        }
    });

    useEffect(() => {
        if (user !== '' && pass !== '') {
            getLogged({ variables: {} }); 
        }
        setTimeout(function(){
            setLoading(false);
        },500);
    }, [getLogged, user, pass]);

    const logInGo = () => {
        if (dataUser) {
            localStorage.setItem('token', dataUser.loginUser.token)
            setIsLoggedin(true)
            setUserType(dataUser.loginUser.user.role)
            setOrganizationID(dataUser.loginUser.user.organization[0].id)
        }
    };

    const updateUser = (event) => {
        setUser(event.target.value)
    }

    const updatePass = (event) => {
        setPass(event.target.value)
    }

    return (
        <div className="row login-container">

            {loading ? (
                <Loader></Loader>
            ):(
                <div className="loging-box">
                <p>Baking some metrics for you.</p>
                <p>But first, please validate they will be only for your eyes:</p>

                <div className="group">
                    <label htmlFor="user">Email</label>
                    <input type="text" name="user" onChange={e => updateUser(e)} />
                    <p className={failedLogIn ? 'error show' : 'error'}>Sorry, that is not a validate email</p>
                </div>

                <div className="group">
                    <label htmlFor="pass">Password</label>
                    <input type="password" name="pass" onChange={e => updatePass(e)} />
                    <p className={failedLogIn ? 'error show' : 'error'}>No, that is not the correct password</p>
                </div>

                <button onClick={logInGo} >Log In</button>
            </div>
            )}

            

        </div>
    );

}

export default LogIn;