// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/search.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".search {\n  background-color: #242424;\n  border: solid 1px #2f2a34;\n  border-radius: 2px;\n  display: inline-block;\n  float: right;\n  height: 30px;\n  width: 150px; }\n  .search input {\n    -webkit-appearance: none;\n    background-color: transparent;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-position: 95% center;\n    background-repeat: no-repeat;\n    border: none;\n    color: #ffffff;\n    height: inherit;\n    padding-left: 5px;\n    padding-right: 24px;\n    width: inherit; }\n", ""]);
// Exports
module.exports = exports;
