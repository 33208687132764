import React, { useState } from "react";
import { Link } from "react-router-dom";
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import "../styles/components/experiences.scss";

const Experiences = (organizationID) => {


    const getExperiences = gql`
        query {
            getModulesFromOrganization(organizationId: "${organizationID.organizationID}") {
            id
            name
            description
            }
        }
        `;

    const { data: dataExperiences } = useQuery(getExperiences);

    const [path] = useState("/experience-details")


    return (
        <div className="experiences">
            <div>
                <div className="row">
                    <div className="title col-12">
                        All Experiences
                        </div>
                </div>
                <div className="row">
                    {dataExperiences ? (dataExperiences.getModulesFromOrganization.map((experience, i) => {
                        return (
                            <div className="col-6" key={i}>
                                <Link key={i} to={{ pathname: path, state: { selectedExperience: experience, from: [{ path: "/experiences", name: "All Experiences" }] } }}>
                                    <div className="experience">
                                        <img src={"/dummy-info/experience-2.jpg"} alt="" className="experience-image" />
                                        <div className="experience-title">
                                            {experience.name}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })):(console.log('no dataExperiences, redirecting to home screeen'))}
                </div>
            </div>
        </div>
    );
}

export default Experiences;