import React from "react";
import "../styles/components/percentage-bars.scss"

const PercentageBar = props => {

    return(
        <div>
            
            <div className="percentage-bar-container">
                <div className="header-container"> <span>{props.title}</span> </div>
            
                <div className="bars-container">
                    {props.type === "multiple"?(
                        props.data.map((bar, index) => {
                            return(
                                <div key={index}>
                                    <div className="bar-cont">
                                        <div className="bar-per" style={{ width: bar.percentage + "%" }}></div>
                                        <div className="bar-number" style={{ left: bar.percentage-2 + "%" }}>{bar.percentage + "%"}</div>
                                    </div>
                                    <div className="text">
                                        {bar.title}
                                    </div>
                                </div>
                            );
                        })
                    ):(
                        <div className="single">
                            <div className="bar-cont">
                                <div className="bar-per" style={{ width: props.percentage + "%" }}></div>
                                <div className="bar-number" style={{ left: props.percentage-2 + "%" }}>{props.percentage + "%"}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            
        </div>
    )
}

export default PercentageBar;