import React, { Component } from "react";
import "../styles/components/header.scss";


export default class Header extends Component{
    render(){
        return (
            <header>
                
            </header>
        );
    }
}