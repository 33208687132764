import React from "react"
import "../styles/components/simulation-stats.scss";
import CircularProgress from "./circular-progress";
import gauge from "../img/gauge.png"

const SimulationStats = (props) =>{

    let score = props.questions.scene? ((props.questions.score * 100)/props.questions.scene.maxScore) : 0;
    let labels = props.questions.evaluationAnswers.map(q => {
        return q.question.question
    })
    let filteredLabels = labels.filter((item, i) =>{
        return labels.indexOf(item) === i
    })

    let data = [];
    filteredLabels.forEach(name =>{
        let group = props.questions.evaluationAnswers.filter(item =>{
            return item.question.question === name
        })
        let recentDate = new Date(Math.max.apply(null, group.map(function(e) {
            return new Date(e.session.created);
        })));
        let recentQuestions = props.questions.evaluationAnswers.filter(item =>{
            return new Date(item.session.created).toString() === recentDate.toString() && item.question.question === name;
        })
        data.push(recentQuestions[0]);
    })
    let normalQuestions = []
    let yesNoQuestions = []
    let additions = null
    if(props.multiple){
        normalQuestions = data.filter(q =>{
            return q.question.question.includes('StrikeInitialQuestion')
        })
        yesNoQuestions = data.filter(q => {
            return q.question.options[0] === 'Yes'
        })
    }
    if(props.additions && props.questions.additions.length > 0){
        additions = JSON.parse(props.questions.additions[0].json);
        console.log(additions);
    }

    return(
        <div className="stat-container">
            <div className="header">
                {props.title}
                <span>Simulation Score:&nbsp;&nbsp;<span className="score">{score===100?(score + "%"):(score.toFixed(2) + "%") }</span> </span>
            </div>
            <div className="stat-content">
                {props.yesno? (
                    data.map((question, i) =>{
                        return(
                            <div className="question yesno" key={i}>
                                <div className="text">
                                    {question.question.question}
                                </div>
                                <div className={question.result==="Correct"?('answer correct'):('answer incorrect')}>
                                    {question.question.options[question.answer]}
                                </div>
                            </div>
                        )
                    })
                ):(
                    props.multiple? (
                        <div>
                            {normalQuestions.map((question, i) =>{
                                return(
                                    <div className="question" key={i}>
                                        <div className="pre-text">
                                            Answer for interaction #{i+1}: 
                                        </div>
                                        <div className="text">
                                            {question.question.options[question.answer]}
                                        </div>
                                        <div className={question.result==="Correct"?('answer correct'):('answer incorrect')}>
                                            {question.result}
                                        </div>
                                    </div>
                                )
                            })}
                            <div className="multiple-yesno-container">
                                <div className="multiple-yesno-title">Posture Details</div>
                                {yesNoQuestions.map((question, i) =>{
                                    return(
                                        <div className="question yesno" key={i}>
                                            <div className="text">
                                                {question.question.question}
                                            </div>
                                            <div className={question.result==="Correct"?('answer correct'):('answer incorrect')}>
                                                {question.question.options[question.answer]}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ):(
                        data.map((question, i) =>{
                            return(
                                <div className="question" key={i}>
                                    <div className="pre-text">
                                        Answer for interaction #{i+1}: 
                                    </div>
                                    <div className="text">
                                        {question.question.options[question.answer]}
                                    </div>
                                    <div className={question.result==="Correct"?('answer correct'):('answer incorrect')}>
                                        {question.result}
                                    </div>
                                </div>
                            )
                        })
                    )
                )}
                { typeof additions !== undefined && additions !== null ? (
                   <div className="additions">
                       <div className="title">Audio Emotion Analysis</div>
                       <div className="item-cont">
                           <div className="vibes">
                               <div className="vibe positive-vibes">
                                   <div className="vibe-title">
                                        POSITIVE VIBES
                                   </div>
                                   <div className="vibe-stats">
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibeAssertive.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibeAssertive.toFixed(2)}/10 <strong>Assertive</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibeAuthentic.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibeAuthentic.toFixed(2)}/10 <strong>Authentic</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibeCaptivating.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibeCaptivating.toFixed(2)}/10 <strong>Captivating</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibeClear.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibeClear.toFixed(2)}/10 <strong>Clear</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibeConfident.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibeConfident.toFixed(2)}/10 <strong>Confident</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibeEnergetic.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibeEnergetic.toFixed(2)}/10 <strong>Energetic</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibeOrganized.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibeOrganized.toFixed(2)}/10 <strong>Organized</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibePersonable.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibePersonable.toFixed(2)}/10 <strong>Personable</strong>
                                           </div>
                                       </div>

                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar positive" style={{width:additions.positiveVibePersuasive.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.positiveVibePersuasive.toFixed(2)}/10 <strong>Persuasive</strong>
                                           </div>
                                       </div>

                                       
                                   </div>
                               </div>
                               <div className="vibe positive-vibes">
                                    <div className="vibe-title">
                                        NEGATIVE VIBES
                                    </div>

                                    <div className="vibe-stats">
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeArrogant.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeArrogant.toFixed(2)}/10 <strong>Arrogant</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeBelligerent.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeBelligerent.toFixed(2)}/10 <strong>Belligerent</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeBoring.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeBoring.toFixed(2)}/10 <strong>Boring</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeCondescending.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeCondescending.toFixed(2)}/10 <strong>Condescending</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeConfusing.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeConfusing.toFixed(2)}/10 <strong>Confusing</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeDetached.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeDetached.toFixed(2)}/10 <strong>Detached</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeDitsy.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeDitsy.toFixed(2)}/10 <strong>Ditsy</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeNervous.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeNervous.toFixed(2)}/10 <strong>Nervous</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibePushy.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibePushy.toFixed(2)}/10 <strong>Pushy</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeTimid.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeTimid.toFixed(2)}/10 <strong>Timid</strong>
                                           </div>
                                       </div>
                                       <div className="vibe-stat">
                                           <div className="vibe-bar-cont">
                                               <div className="vibe-bar negative" style={{width:additions.negativeVibeUnapproachable.toFixed(2)*10+"%"}}></div>
                                           </div>
                                           <div className="vibe-stat-text">
                                                {additions.negativeVibeUnapproachable.toFixed(2)}/10 <strong>Unapproachable</strong>
                                           </div>
                                       </div>
                                    </div>
                               </div>
                           </div>

                           <div className="reports">
                                <div className="report vocal">
                                    <div className="graphic">
                                        <CircularProgress
                                            degrees={additions.overall*(180/10)}
                                            users={additions.overall.toFixed(2)+" / 10"}
                                            color='#38bfae'
                                            noText={true}
                                        />
                                    </div>
                                    <div className="report-content">
                                        <div className="report-title">Vocal Delivery</div>
                                        <div className="report-content">{additions.tipOverall}</div>
                                    </div>
                                </div>

                                <div className="report pace">
                                    <div className="graphic">
                                        <img src={process.env.PUBLIC_URL + gauge} alt=""/>
                                        <div className="needle" style={{transform:'rotate('+ ((additions.pace*(180/10))-90)+'deg)'}}></div>
                                    </div>
                                    <div className="report-content">
                                        <div className="report-title">Pace</div>
                                        <div className="report-content">{additions.tipPace}</div>
                                    </div>
                                </div>
                                <div className="report clarity">
                                    <div className="graphic">
                                        <CircularProgress
                                            degrees={additions.clarity*(180/10)}
                                            users={(additions.clarity.toFixed(2))+" / 10"}
                                            color='#663082'
                                            noText={true}
                                        />
                                    </div>
                                    <div className="report-content">
                                        <div className="report-title">Clarity</div>
                                        <div className="report-content">{additions.tipClarity}</div>
                                    </div>
                                </div>
                           </div>
                           
                       </div>
                   </div>
                ):("")
                }
            </div>
        </div>
    )
}

export default SimulationStats;