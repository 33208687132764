import React, { Component } from "react";
import "../styles/components/menu.scss";
import {NavLink} from "react-router-dom";

//menu logos
import analyticsLogo from "../img/menu-icons/analytics.svg"
import experiencesLogo from "../img/menu-icons/experiences.svg"
// import settingsLogo from "../img/menu-icons/settings.svg"



export default class Menu extends Component{
    
    checkActive = (match, location) => {
        //some additional logic to verify you are in the home URI
        if(!location) return false;
        const {pathname} = location;
        return pathname === "/";
    }

    render(){
        return (
           <div className="menu">
               <NavLink to="/" className="menu-btn" activeClassName="active" isActive={this.checkActive}> 
                    <div className="btn-cont">
                        <img src={analyticsLogo} alt="menu-logo"/> General Analytics 
                    </div>
                </NavLink>
{/* 
               <NavLink to="user-details" className="menu-btn" activeClassName="active"> 
                    <div className="btn-cont">
                        <img src={userLogo} alt="menu-logo"/> User Details 
                    </div>
                </NavLink> */}
                
               <NavLink to="/experiences" className="menu-btn" activeClassName="active"> 
                    <div className="btn-cont">
                        <img src={experiencesLogo} alt="menu-logo"/>Experiences 
                    </div>
                 
                </NavLink>

               {/* <NavLink to="/settings" className="menu-btn" activeClassName="active"> 
                    <div className="btn-cont">
                        <img src={settingsLogo} alt="menu-logo"/> Settings 
                    </div>
                </NavLink> */}
               
           </div>
        );
    }
}