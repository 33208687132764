import React, { Component } from "react";
import PasswordToogle from './password-toogle';
import UserName from './user-name';
import { Link } from "react-router-dom";
import "../styles/components/user-details.scss";
import Breadcrumb from "./breadcrumb";

export default class UserDetails extends Component {

    state = {
        selectedUser: this.props.item,
        from: this.props.from,
        last: this.props.last,
        organizationID: this.props.organizationID,
        password: ""
    };



    static getDerivedStateFromProps(props, state) {

        if (props.location.state) {
            let user = state.selectedUser;
            let from = state.from;

            if (props.location.state.selectedUser !== state.selectedUser) {
                user = props.location.state.selectedUser
            }

            if (props.location.state.from !== state.from) {
                from = props.location.state.from
                from.push({ name: user.name, state: {} })
            }

            return {
                selectedUser: user,
                from: from
            }
        }
    };

    lastSession = data => {
        if(data){
            let mostRecentDate = new Date(Math.max.apply(null, data.experiences.map(item => {
                let sessionDates = item.sessions[item.sessions.length - 1].created;
                return new Date(sessionDates);
            })));
            if (mostRecentDate.toString() === "Invalid Date") {
                return ('No Experiences Started')
            }
            let mostRecentExperience = data.experiences.filter(item => {
                let date = new Date(item.sessions[item.sessions.length - 1].created);
                return date.getTime() === mostRecentDate.getTime();
            })[0];
            return (mostRecentExperience);
        }else{
            return null;
        }
    };


    render() {
        let lastSession = this.lastSession(this.state.selectedUser)
        let { from } = this.state;

        return (
            <div>
                {this.state.selectedUser ? (
                    <div className="user-details">
                        {from ? <Breadcrumb from={from}></Breadcrumb> : ""}
                        <h1>Information per user VR experience</h1>
                        
                        <div className="details">
                            <div className="user-info">
                                <div className="wrapper">
                                    <div className="avatar">
                                        <img src={process.env.PUBLIC_URL + "/dummy-info/user-1.jpg"} alt="" />
                                    </div>
                                    <div className="info">

                                        <UserName username={this.state.selectedUser.name} email={this.state.selectedUser.email} />

                                        {this.state.selectedUser.email}
                                    </div>
                                </div>
                                <PasswordToogle userID={this.state.selectedUser.id} organizationID={this.state.organizationID} password={this.state.selectedUser.quickpasses[0].password} />
                            </div>

                            <div className="last-scened">
                                <div className="info">
                                    <div className="title">Last Scene Visited</div>

                                    <div className="date">{lastSession.sessions ? (new Date(lastSession.sessions[0].created).toDateString()) : ("No Experience Started")}</div>
                                </div>
                                <div className="experience">
                                    {lastSession.sessions ?
                                        (
                                            <div>
                                                <img
                                                    src={process.env.PUBLIC_URL + '/dummy-info/experience-2.jpg'}
                                                    alt="experience-avatar"
                                                />
                                                <div className="details">{lastSession.module.name}</div>
                                                <div className="status">
                                                    {typeof lastSession.completionDate === 'undefined' ? (
                                                        <div className="in-progress">In Progress</div>
                                                    ) : (
                                                        <div className="complete">Complete</div>
                                                    )}
                                                </div>
                                            </div>

                                        ) : (
                                            <div></div>
                                        )
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="all-experiences">
                            <div className="title">
                                Experiences of this user:{" "}
                                {this.state.selectedUser.experiences.length}
                            </div>

                            <div className="experiences">
                                {this.state.selectedUser.experiences.map((item, i) => {
                                    return (
                                        <div key={i} className="experience">
                                            <Link key={i} to={{ pathname: '/user-details-christus', state: {selectedUser: this.state.selectedUser, from: [{ path: "/user-details", name: this.state.selectedUser.name, state:{selectedUser: this.state.selectedUser}}] } }}>
                                                <img
                                                    src={process.env.PUBLIC_URL + "/dummy-info/experience-2.jpg"}
                                                    alt="experience-avatar"
                                                />
                                                <div className="details">{item.module.name}</div>
                                                <div className="progress-bar-cont">
                                                    {" "}
                                                    <div
                                                        className="progress-bar-fill"
                                                        style={{ width: item.score > 0 ?("100%"):("0")}}
                                                    ></div>{" "}
                                                </div>
                                                <div
                                                    className={
                                                        item.score > 0 ? "score" : "score not-complete"
                                                    }
                                                >
                                                    {item.score}
                                                    <span>final score</span>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ) : ('')}
            </div>
        );
    }
}
