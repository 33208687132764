import React, { Component } from "react";
import "../styles/components/graphics.scss";
var Chart = require('chart.js');

export default class Graphics extends Component{
	componentDidMount = () =>{
		var config = {
			type: this.props.type,
			
			data: {
				labels: this.props.labels,
				datasets: this.props.data,

			},
			options: {
				
				responsive: true,
				maintainAspectRatio : false,
				pointRadius: 40,
				scales: this.props.scales,
				legend: {
					display: this.props.legend,
					position: 'bottom',
					align: 'start',
					labels: {
						fontColor: '#fff'
					}
				}
			}
		};
		var ctx = document.getElementById(this.props.unique_id).getContext('2d');
		new Chart(ctx, config);
	}
	
	render = () =>{
		return (
			<div className="linear-graphic-container">
				<div className="header-container">
					<span>{this.props.title}</span>	  {" " + this.props.date}
				</div>

				<div className="graphic-container">
					<canvas id={this.props.unique_id}></canvas>
				</div>
			</div>
		);
    }
}