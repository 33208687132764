import React, { Component } from "react";
import SimulationStats from './simulation-stats'
import "../styles/components/user-details.scss";
import Breadcrumb from "./breadcrumb";

export default class UserDetailsChristus extends Component {

    state = {
        selectedUser: this.props.item,
        from: this.props.from,
        last: this.props.last,
        organizationID: this.props.organizationID,
        password: ""
    };


    static getDerivedStateFromProps(props, state) {

        if (props.location.state) {
            let user = state.selectedUser;
            let from = state.from;

            if (props.location.state.selectedUser !== state.selectedUser) {
                user = props.location.state.selectedUser
            }
            
            if (props.location.state.from !== state.from) {
                from = props.location.state.from
                from.push({ name: user.experiences[0].module.name, state: {} })
            }

            return {
                selectedUser: user,
                from: from
            }
        }
    };

    render() {
        let { from } = this.state;

        return (
            <div className="user-details">
                {from ? <Breadcrumb from={from}></Breadcrumb> : ""}
                <h1>Information per user VR experience</h1>

                {this.state.selectedUser ? (this.state.selectedUser.experiences[0] ? (
                    <div className="custom-christus-views">
                        {this.state.selectedUser.experiences[0].sceneData[1] ? (
                            <div className="sim-box sim-1">
                                <SimulationStats
                                    title="Simulation: Identifying Physical Behaviors"
                                    yesno={true}
                                    questions={this.state.selectedUser.experiences[0].sceneData[1]}
                                />
                            </div>
                        ) : ('')}
                        {this.state.selectedUser.experiences[0].sceneData[2] ? (
                            <div className="sim-box sim-2">
                                <SimulationStats
                                    title="Simulation: Verbal De-Escalation"
                                    additions={true}
                                    questions={this.state.selectedUser.experiences[0].sceneData[2]}
                                />
                            </div>
                        ) : ('')}
                        {this.state.selectedUser.experiences[0].sceneData[3] ? (
                            <div className="sim-box sim-3">
                                <SimulationStats
                                    title="Simulation: Avoiding Strike"
                                    questions={this.state.selectedUser.experiences[0].sceneData[3]}
                                />
                            </div>
                        ) : ('')}
                    </div>
                ) : ('')):('')}
            </div>
        );
    }
}
