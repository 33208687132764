import React from 'react';
import "../styles/components/loader.scss"

const Loader = () => {
    return(
        <div className="ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default Loader;