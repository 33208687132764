import React, { useState } from "react";
import "../styles/components/client-select.scss";
var clientsA = {
    clients : [
        {
            name : "Christus Health",
            image : "/dummy-info/christus-logo.svg"
        }
    ]
}
 const ClientSelect = ({setIsLoggedin, setUserType}) =>{
    const [clients] = useState(clientsA.clients)

    const clientClicked = () =>{
        setUserType('USER')
        setIsLoggedin(true)
    }
    return (
        <div className="col-10 offset-1 client-select">
            <div className="title">
                Welcome, super!
            </div>
            <div className="clients-continer">
                <div className="sub-title">
                    All Clients
                </div>
                {clients.map((client, i)=>{
                    return(
                        <div className="client col-3" key={i} onClick={clientClicked}>
                            <img
                                src={process.env.PUBLIC_URL + client.image}
                                alt="experience-avatar"
                            />
                            <div className="client-name">
                                {client.name}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default ClientSelect;