import React from "react";
import { Link } from "react-router-dom"
import "../styles/components/breadcrumbs.scss";

const Breadcrumb = (props) => {

    const { from } = props;
    return (

        <div className="breadcrumbs">
            <ul className="list-inline">
                {from.map((item, i) =>
                    <li className="list-inline-item" key={i}>
                        {
                            item.path ?
                                (
                                    <Link to={{ pathname: item.path, state: item.state }}>
                                        {item.name}
                                    </Link>
                                )
                                : (
                                    <div className="active">{item.name}</div>
                                )
                        }
                    </li>
                )}
            </ul>
        </div>
    )

}

export default Breadcrumb