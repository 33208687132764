import React, { useState, useEffect } from "react";
import "../styles/components/user-name.scss";
import icon from "../img/rename.svg";
import x from "../img/x.svg";
import check from "../img/check.svg"

import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const UserName = props => {
    const [hidden, setHidden] = useState(true);
    const [username, setUsername] = useState("");
    const [usernameTemp, setUsernameTemp] = useState("");
    const [email, setEmail] = useState("");

    const UPDATE_USER_QUERY = gql`
    mutation {
        updateUserName(email: "${email}", newName: "${username}") {
            id
            name
        }
    }
    `;

    const [addTodo] = useMutation(UPDATE_USER_QUERY);

    const handleUsernameChange = e => {
        setUsername(e.target.value);
    };

    const editName = () => {
        setHidden(false);
        setUsernameTemp(username)
    };

    const restoreName = () => {
        setHidden(true);
        setUsername(usernameTemp)
    };

    const saveName = () => {
        setHidden(true);
        addTodo();
    };

    useEffect(() => {
        setUsername(props.username);
        setEmail(props.email)
    }, [props.username, props.email]);


    return (
        <div className="user-name">
            <input
                name="username"
                value={username}
                onChange={handleUsernameChange}
                readOnly={hidden}
            />
            {hidden?(
                <button onClick={editName}>
                    <img
                        src={process.env.PUBLIC_URL + icon}
                        alt="button-pass"
                    />
                </button>
            ):(
              <div>
                  <button onClick={restoreName}>
                    <img
                        src={process.env.PUBLIC_URL + x}
                        alt="button-pass"
                    />
                </button>
                <button onClick={saveName}>
                    <img
                        src={process.env.PUBLIC_URL + check}
                        alt="button-pass"
                    />
                </button>
              </div>
            )}
        </div>
    );
};

export default UserName;
