import React from 'react';
import PropTypes from 'prop-types';
import "../styles/components/searchbar.scss";

const Searchbar = props => {
    return (
        <div className="search">
            <input type="text" className="input" value={ props.text } onChange={ props.onTextChanged } />
        </div>
    );
};

Searchbar.propTypes = {
    text: PropTypes.string.isRequired,
    onTextChanged: PropTypes.func.isRequired
};

export default Searchbar;