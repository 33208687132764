import React, { useState } from "react";
import "../styles/components/general-analytics.scss";

import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

//graphic comps
import Graphic from "./graphics";
import GraphicT from "./graphics-test";
import List from "./list";
import Loader from "./loader";
import ErrorScreen from './error-screen';

const year = new Date().getFullYear();
const years = Array.from(new Array(10), (val, index) => year - index);
let filterDate = 'Global';


const months = [
  { label: "Select", value: '00' },
  { label: "January", value: '01' },
  { label: "February", value: '02' },
  { label: "March", value: '03' },
  { label: "April", value: '04' },
  { label: "May", value: '05' },
  { label: "June", value: '06' },
  { label: "July", value: '07' },
  { label: "August", value: '08' },
  { label: "September", value: '09'},
  { label: "October", value: '10' },
  { label: "November", value: '11' },
  { label: "December", value: '12' }
];



const timeSpentOnExperiences = (experiences, experiencesLabels) => {
  let labels = experiencesLabels.map((item) => { return (item.name) });
  let time = labels.map((item) => {
    let filtered = experiences.filter((exp) => {
      return exp.module.name === item
    });
    let totalTime = 0;

    filtered.forEach(exp => {
      exp.sessions.forEach(ses => {
        totalTime += ses.time;
      })
    })
    return (totalTime / 60 / 60).toFixed(1);
  });
  return (
    <Graphic
      title="Time Spent on Experiences:"
      date={filterDate}
      type="pie"
      unique_id="pie-graph"
      footnote= "Time shown in hours"
      labels={labels}
      data={time}
      backgroundColor={[
        "#cf6b6f",
        "#4a487d",
        "#913d6c",
        "#4e72bf",
        "#8167bf"
      ]}
      legend={true}
    />
  )
};

const usagePerExperiences = (experiences, experiencesLabels) => {
  let labels = experiencesLabels.map((item) => { return (item.name) });
  let usage = labels.map((item) => {
    let totalUsagae = 0;
    let filtered = experiences.filter((exp) => {
      return exp.module.name === item;
    });
    filtered.forEach(ses => {
      totalUsagae += ses.sessions.length;
    });

    return totalUsagae;
  })

  return (
    <Graphic
      title="Usage per Experience:"
      date={filterDate}
      type="bar"
      unique_id="bar-graph"
      labels={labels}
      data={usage}
      scales={{
        yAxes: [
          {
            ticks: {
              min: 0
            }
          }
        ]
      }}
      backgroundColor="#d36a70"
    />
  );
};

const globalActivity = (experiences) => {

  let allDates = [];
  let allSesions = [];
  experiences.map((exp) => {
    exp.sessions.map((ses) => {
      allSesions.push(ses);
      allDates.push(new Date(ses.created).toDateString());
      return(ses)
    })
    return(exp)
  })
  let labels = allDates.sort((a, b) => new Date(a) - new Date(b)).map(function (date) { return date }).filter(function (date, i, array) { return array.indexOf(date) === i; }).map(function (time) { return time });

  let data = labels.map(lbl => {
    let filtered = allSesions.filter(item => {

      return lbl === new Date(item.created).toDateString();
    });
    return filtered.length;
  })
  return (
    <Graphic
      title="Activity:"
      date={filterDate}
      type="line"
      unique_id="line-graph"
      labels={labels}
      legendText="Number of Users"
      data={data}
      scales={{
        yAxes: [
          {
            ticks: {
              min: 0
            }
          }
        ]
      }}
      legend={true}
      backgroundColor="#913d6c"
    />
  )
};

const globalActivityPerExperience = (experiences, experiencesLabels) => {
  let experiencesName = experiencesLabels.map((item) => { return (item.name) });
  let allDates = [];
  let dataset = [];
  let backgroundColor = ["#4e72bf", "#cf6b6f", "#8167bf", "#4a487d", "#913d6c"];
  experiences.map((exp) => {
    exp.sessions.map((ses) => {
      allDates.push(new Date(ses.created).toDateString());
      return(ses)
    })
    return(exp)
  })
  let labels = allDates.sort((a, b) => new Date(a) - new Date(b)).map(function (date) { return date }).filter(function (date, i, array) { return array.indexOf(date) === i; }).map(function (time) { return time });
  let dataPerExperience = experiencesName.map(expName => {
    let experienceData = [];
    let filtered = experiences.filter(exp => {
      return expName === exp.module.name;
    });
    filtered.map((exp) => {
      exp.sessions.map((ses) => {
        experienceData.push(ses);
        return(ses)
      })
      return(exp)
    })
    return experienceData
  })

  let dataPerDate = labels.map(lbl => {
    let filtered = dataPerExperience.map(exp => {
      return exp.filter(ses => {
        return new Date(ses.created).toDateString() === lbl;
      })
    })
    return filtered
  })
  let data = experiencesName.map((data, index) => {
    return dataPerDate.map((exp) => { return exp[index].length });
  })
  data.map((dset, index) => {
    dataset.push({ data: dset, backgroundColor: backgroundColor[index], label: experiencesName[index] });
    return(dset)
  })

  return (
    <GraphicT
      title="Activity per Experience:"
      date={filterDate}
      type="line"
      unique_id="line-graph2"
      labels={labels}
      data={dataset}
      legend={true}
      scales={{
        yAxes: [
          {
            ticks: {
              min: 0
            }
          }
        ]
      }}
    />
  )
}

const GeneralAnalytics = (organizationID) => {
  const [analyticsFilter, setAnalyticsFilter] = useState('global');
  const [filterYears, setFilterYears] = useState(false);
  const [filterMonths, setfilterMonths] = useState(false);
  const [filterWeeks, setfilterWeeks] = useState(false);
  const [beginYear, setBeginYear] = useState(2018);
  const [untilYear, setUntilYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState('0')
  const [beginMonth, setBeginMonth] = useState('01');
  const [untilMonth, setUntilMonth] = useState(new Date().getMonth()+1);
  const [week, setWeek] = useState('0');
  const [beginDay, setBeginDay] = useState('01');
  const [untilDay, setUntilDay] = useState(new Date().getDate());
  
  const Get_Users = gql`
    query {
      getUsersFromOrganization(organizationId: "${organizationID.organizationID}") {
        id
        name
        email
        quickpasses {
          password
        }
        experiences {
          id
          score
          completionDate
          module {
            id
            name
          }
          sessions(last: 1) {
            id
            created
          }
          sceneData {
            continuity
            continuityUntilCompletion
            completionDate
            score
            scene {
                id
                name
                maxScore
                sceneData {
                    time
                    score
                    continuity
                    continuityUntilCompletion
                    completionDate
                }
            }
            evaluationAnswers{
                session{
                  id
                  created
                }
                question{
                    question
                    options
                }
                answer
                result
                group
            }
            additions{
                json
                type
            }
          }
        }
      }
    }
  `;

  const Get_Experiences = gql`
    query {
      getModulesFromOrganization(organizationId: "${organizationID.organizationID}") {
        id
        name
        description
      }
    }
  `;

  const GetExperiencePerDate = gql`
    query {
      getExperiencePerDate(
        organizationId: "${organizationID.organizationID}"
        greaterThan: "${beginYear}-${beginMonth}-${beginDay}"
        lessThan: "${untilYear}-${untilMonth}-${untilDay}"
      ) {
        id
        module {
          id
          name
        }
        sessions(greaterThan: "${beginYear}-${beginMonth}-${beginDay}", lessThan: "${untilYear}-${untilMonth}-${untilDay}") {
          id
          time
          created
        }
      }
    }
  `;

  

  const { loading: loadingUser, error: errorUser, data: dataUsers} = useQuery(Get_Users);
  const { loading: loadingExperiences, error: errorExperiences, data: dataExperiences} = useQuery(Get_Experiences);
  const { loading: loadingExperiencesPerDate, error: errorExperiencesPerDate, data: dataExperiencesPerDate, refetch: refetchExperiencePerDate, networkStatus} = useQuery(GetExperiencePerDate,{notifyOnNetworkStatusChange: true, fetchPolicy: 'network-only'});


  if (networkStatus === 4) return 'Refetching!';
  
  if (loadingUser) return <Loader/>;
  if (loadingExperiences) return <Loader/>;
  if (loadingExperiencesPerDate) return <Loader/>;

  if (errorUser) return <ErrorScreen/>;
  if (errorExperiences) return <ErrorScreen/>;
  if (errorExperiencesPerDate) return <ErrorScreen/>;

  // Toggle if a filter is active or not.
  const onFilterActiveToggle = event => {
    const target = event.target;
    const id = target.id;

    if (id === "filterYearsActive") {
      setFilterYears(!filterYears);
      setBeginYear(new Date().getFullYear());
      setUntilYear(new Date().getFullYear());
      refetchExperiencePerDate();
      
    }

    if (id === "filterMonthsActive" && filterYears) {
      setfilterMonths(!filterMonths);
    
    }

    if (id === "filterWeeksActive" && filterMonths) {
      setfilterWeeks(!filterWeeks);
    }
  };

  const onYearChange = event => {
    let year = event.target.options[event.target.selectedIndex].value;
    setBeginYear(year);
    setUntilYear(year);
    filterDate = "";
    refetchExperiencePerDate();

  }

  const onMonthChange = event => {
    let mnth = event.target.options[event.target.selectedIndex].value;
    setMonth(mnth)
    if (mnth < 9){
      mnth = "0" + mnth
    }
    setBeginMonth(mnth);
    setUntilMonth(mnth);
    refetchExperiencePerDate();
  }

  const onWeekChange = event => {
    let week = event.target.options[event.target.selectedIndex].value;
    switch(week){
      case '1':
        setBeginDay('01');
        setUntilDay('07');
        setWeek('1');
        break;
      case '2':
        setBeginDay('08');
        setUntilDay('16');
        setWeek('2');
        break;
      case '3':
        setBeginDay('17');
        setUntilDay('21');
        setWeek('3');
        break;
      case '4':
        setBeginDay('22');
        setUntilDay('29');
        setWeek('4');
        break;
      default:
        setBeginDay('01');
        setUntilDay('29');
        setWeek('0');
        break;
    }
    refetchExperiencePerDate();
  }

  const toggleFilter = event => {
    let value = event.currentTarget.value
    setAnalyticsFilter(value)
    if(value === "specific"){
      setFilterYears(!filterYears);
      setBeginYear(new Date().getFullYear());
      setUntilYear(new Date().getFullYear());
      refetchExperiencePerDate();
      setMonth('0');
      setWeek('0');
    }
    if(value === "global"){
      setFilterYears(false);
      setfilterMonths(false);
      setfilterWeeks(false);
      //year reset
      setBeginYear('2019');
      setUntilYear(new Date().getFullYear());
      //mont reset
      setBeginMonth('01');
      setUntilMonth('12');
      //week reset
      setBeginDay('01');
      setUntilDay('29');
      refetchExperiencePerDate();
    }
  }

  return (
    <div className="general-analytics">
      <div className="row">
        <div className="col-6">
          <List
            title={"Total Users Registered: " + dataUsers.getUsersFromOrganization.length + " Users"}
            isUserList={true}
            users={dataUsers.getUsersFromOrganization}
          />
        </div>
        <div className="col-6">
          <List
            title={
              "Experiences on the System: " + dataExperiences.getModulesFromOrganization.length
            }
            experiences={dataExperiences.getModulesFromOrganization}
            path= {[{ path: "/experiences", name: "All Experiences" }]}
          />
        </div>
        <div className="col-12">
          <div className="filter-box">
            <div className="row">
              <div className="col-3">
                <div className="title">Filter Analytics</div>
              </div>
              <div className="col-2">
                <label htmlFor="global">
                  <input
                    checked={analyticsFilter === "global" ? ("checked"):("")}
                    type="radio"
                    name="filter"
                    id="global"
                    value="global"
                    onChange={e => toggleFilter(e)}
                  />
                  <span className="checkmark"></span>
                  Global
                </label>
              </div>
              <div className="col-2">
                <label htmlFor="specific">
                  <input
                    checked={analyticsFilter === "specific" ? ("checked"):("")}
                    type="radio"
                    name="filter"
                    id="specific"
                    value="specific"
                    onChange={e => toggleFilter(e)}
                  />
                  <span className="checkmark"></span>
                  Specific
                </label>
              </div>
              <div className="col-5">
                <div
                  className={`specific-filters ${
                    analyticsFilter === "specific" ? "" : "hidden"
                    }`}
                >
                  <div className="row">
                    <div className="col-4">
                      <div
                        className={`filter-container ${
                          filterYears ? "active" : ""
                          }`}
                      >
                        <span
                          id="filterYearsActive"
                          className="filter-active-check years-check"
                          onClick={e => onFilterActiveToggle(e)}
                        ></span>
                        <select
                          className="filterYears"
                          disabled={
                            filterYears ? "" : "disabled"
                          }
                          defaultValue={untilYear}
                          onChange = {e => onYearChange(e)}
                        >
                          {years.map((year, index) => {
                            return (
                              <option key={`year${index}`} value={year}>
                                {year}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        className={`filter-container ${
                          filterMonths ? "active" : ""
                          }`}
                      >
                        <span
                          id="filterMonthsActive"
                          className="filter-active-check months-check"
                          onClick={e => onFilterActiveToggle(e)}
                        ></span>
                        <select
                          className="filterMonths"
                          disabled={
                            filterMonths ? "" : "disabled"
                          }
                          defaultValue={month}

                          onChange = {e => onMonthChange(e)}
                        >
                          {months.map((month, index) => {
                            return (
                              <option
                                key={`months${month.value}`}
                                value={index}
                              >
                                {month.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        className={`filter-container ${
                          filterWeeks ? "active" : ""
                          }`}
                      >
                        <span
                          id="filterWeeksActive"
                          className="filter-active-check weeks-check"
                          onClick={e => onFilterActiveToggle(e)}
                        ></span>
                        <select
                          className="filterWeeks"
                          disabled={
                            filterWeeks ? "" : "disabled"
                          }
                          defaultValue={week}
                          onChange = {e => onWeekChange(e)}
                        >
                          <option value="0" >Select</option>
                          <option value="1" >Week 1</option>
                          <option value="2" >Week 2</option>
                          <option value="3" >Week 3</option>
                          <option value="4" >Week 4</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          {globalActivity(dataExperiencesPerDate.getExperiencePerDate)}
        </div>
        <div className="col-6">
          {timeSpentOnExperiences(dataExperiencesPerDate.getExperiencePerDate, dataExperiences.getModulesFromOrganization)}
        </div>
        <div className="col-6">
          {usagePerExperiences(dataExperiencesPerDate.getExperiencePerDate, dataExperiences.getModulesFromOrganization)}
        </div>
        <div className="col-12">
          {globalActivityPerExperience(dataExperiencesPerDate.getExperiencePerDate, dataExperiences.getModulesFromOrganization)}
        </div>
      </div>
    </div>
  );

}

export default GeneralAnalytics;




