import React, { useState } from "react";
import "../styles/components/container.scss";
import { BrowserRouter, Route, Redirect } from "react-router-dom"

//import all pages
import LogIn from "./login.jsx";
import Header from "./header";
import Menu from "./menu"
import GenAnalytics from "./general-analytics"
import Experiences from "./experiences"
// import Settings from "./settings"
import ExperienceDetails from "./experience-details"
import UserDetails from "./user-details"
import UserDetailsChristus from "./user-details-christus"
import ClientSelect from "./client-select"
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';


const client = new ApolloClient({
    uri: 'https://vranalyticsapi.sweetrush.com/', // Or 'http://localhost:4000/'
    request: operation => {
        operation.setContext({
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('token'), //Your Auth token extraction logic
            },
        });
    },
})

const Container = () => {
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [userType, setUserType] = useState('USER');
    const [organizationID, setOrganizationID] = useState('');

    return (
        <ApolloProvider client={client}>
            {isLoggedin ? (
                userType === 'SUPER' ? (
                    <div>
                        <div className="row">
                            <Header />
                        </div>
                        <div className="content row">
                            <ClientSelect
                                setIsLoggedin={setIsLoggedin} setUserType={setUserType}
                            />
                        </div>
                    </div>
                ) : (
                        <div>
                            <div className="row">
                                <Header />
                            </div>
                            <div className="content row">
                                <BrowserRouter>
                                    <div className="col-2 no-padding">
                                        <Menu></Menu>
                                    </div>
                                    <div className="col-10">
                                        <Route
                                            exact
                                            path="/"
                                            render={() => (
                                                <GenAnalytics
                                                    organizationID={organizationID}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/user-details"
                                            render={(props) => (
                                                <UserDetails
                                                    {...props}
                                                    organizationID={organizationID}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/user-details-christus"
                                            render={(props) => (
                                                <UserDetailsChristus
                                                    {...props}
                                                    organizationID={organizationID}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/experience-details"
                                            render={(props) => (
                                                <ExperienceDetails
                                                    {...props}
                                                />
                                            )}
                                        />
                                        <Route
                                            exact
                                            path="/experiences"
                                            render={() => (
                                                <Experiences
                                                    organizationID={organizationID}
                                                />
                                            )}

                                        />
                                        <Redirect to="/" />
                                    </div>

                                </BrowserRouter>
                            </div>
                        </div>
                    )
            ) : (
                    <div>
                        <div className="row">
                            <Header />
                        </div>
                        <LogIn setIsLoggedin={setIsLoggedin} setUserType={setUserType} setOrganizationID={setOrganizationID} />
                    </div>
                )}
        </ApolloProvider>
    );
}

export default Container;